/*=================================================================
  Pricing section
==================================================================*/

.price{
	font-size: 60px;
}


.style-2{
	.price-title{
		background: url("../images/blog/img.jpg");
		z-index: 1;

		&:before{
			position: absolute;
			content:"";
			left: 0px;
			top:0px;
			width: 100%;
			height:100%;
			background: rgba(0,0,0,0.5);
			z-index: -1;
		}
	}

}

.style-3{

	.price-title{
		background:#f9f9f9;

	}
	.btn{
		margin-bottom: -20px;
	}

}

