

.sidebar{

  .widget {
     box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 30px rgba(0, 0, 0, 0.05);
     border-top: 4px solid $primary-color;

    .widget-title {
      font-size: 16px;
      color:#333;
    }


    // latest Posts
    &.widget-latest-post {
      .post-meta{
        font-size: 13px;
        color: $black-lighter;
      }
      .media {
        .media-object {
          width: 70px;
          height:70px!important;
        }

        .media-heading {
          a {
            color: $black;
            font-size: 16px;
            font-family: $secondary-font;
          }
        }
        p {
          font-size: 12px;
          color:#808080;
        }
      }
    } 
  }
  //end latest posts

  // Caterogry
  .widget-category {

    ul {
      li {
        margin-bottom: 10px;

        i{
          margin-right: 10px;
          color: $black;
        }
        a {
          color: #837f7e;
          @include transition (all, 0.3s, ease);
          &:before {
            padding-right: 10px;
          }
          &:hover {
            color:$primary-color;
            padding-left: 5px;
          }
        }
      }
    }
  } //end caterogry

  // Tag Cloud
  .widget-tag {
    ul {
      li {
        margin-bottom: 10px;
        display: inline-block;
        margin-right:5px;
        a {
          display: inline-block;
          padding:5px 15px;
          border:1px solid #dedede;
          font-size: 14px;
          color:$black;
          @include transition (all, 0.3s, ease);

          &:hover {
            color:$light;
            background: $primary-color;
            border:1px solid $primary-color;
          }
        }
      }
    }
  }

}

