/*================= 2. HOME ==================*/

/*=== 2.2 BANNER ===*/
.fullscreenbanner-container {
	width:100%;
	position:relative;
	padding:0;
	.tp-bullets.preview4{
		opacity: 1 !important;
		height: auto !important;
		@include desktops {
			bottom: 100px !important;
		}
	}
	.tp-bullets.preview4 .bullet {
	    background: rgba(255, 255, 255, 0.5) !important;
	    border-radius:50%;
	    box-shadow: none!important;
	    width: 6px !important;
	    height: 6px !important;
	    border: 2px solid rgba(255, 255, 255, 0.0) !important;
	    display: inline-block;
	    margin-right: 14px !important;
	    margin-left: 0 !important;
	    margin-bottom: 0px !important;
	    @include transition (background-color 0.2s, border-color 0.2s);
	    float: none !important;
	}
	.tp-bullets.preview4 .bullet:hover, .tp-bullets.preview4 .bullet.selected {
	    box-shadow: none!important;
	    background: rgba(255, 255, 255, 0.0) !important;
	    width: 14px !important;
	    height: 14px !important;
	    border: 2px solid rgba(255, 255, 255, 1) !important;
	    margin-right: 10px !important;
	    margin-left: -4px!important;
	    margin-bottom: -3px!important;
	}
	.tparrows.preview4 .tp-arr-iwrapper {
		display: none !important;
	}
}
.tp-bullets{
	z-index: 99;
}
@include tablet {
	.tparrows.preview4:hover:after{	
		-webkit-transform: rotateY(0);
	 	transform:rotateY(0);
	 }
	.tp-leftarrow.preview4:hover:after{	
		-webkit-transform: rotateY(0); 
		transform:rotateY(0)
	}
}
.fullscreenbanner{
	color: $black-light;
	width:100%;
	position:relative;
}
.tp-caption{
	white-space: normal!important;
	font-weight: 500;
}
.slider-caption{
	text-align: center;
}
.rs-slider2{
	.slider-caption{
		text-align: left;
	}
}
.rs-slider3{
	.slider-caption{
		text-align: left;
	}
}
.tp-caption.rs-caption-1{
	text-transform: uppercase;
	font-size: 65px;
	line-height: 75px;
	font-weight:normal;
	font-family: 'Limelight', cursive;
	span{
		color: $secondary-color;
	}
}
.tp-caption.rs-caption-2{
	font-size: 18px ;
	line-height: 20px ;
}
.rs-caption-rating .ed-rating{
	color:$secondary-color;
	font-size:24px;
}
.fullscreenbanner .tp-dottedoverlay {
    background: rgba(0, 0, 0, 0.15);
    background-image: none!important;
    z-index: 1;
}

/*=== 2.3 CHECK AREA ===*/


.ed-select{
	width: 100%;
	@include tablet {
		width: auto;
	}
}


/*=== 2.4 WHITE SECTION ===*/
.featured{
	.btn{
		display: block;
		font-weight: 500;
	}
	.col-sm-4{
		margin-bottom: 25px;
		@include tablet {
			margin-bottom: 0;
		}
	}
}
.underLine{
	margin: 0 0 20px;
	text-align: center;
	img{
		margin: 0 auto;
	}
}
.ed-room > a{
	position: relative;
	text-decoration: none;
	display: block;
	&:focus{
		outline:none;
	}
}
.ed-room{
	color: $black-light;

	img{
		width: 100%;
	}
	figcaption{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: $primary-color;
		padding: 20px;
		width: 100%;
		display: none;
		z-index: 9;
		p{
			color: $black-light;
		}
	}
}
.ed-room.featured-room figcaption{
	position: relative;
	padding: 20px 10px 45px 10px;
	display: block;
	border-bottom-left-radius:4px;
	border-bottom-right-radius:4px; 
	@include tablet {
		padding: 10px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		display: none;
	}
	@include desktops {
		padding: 15px;
	}
	@include large-desktops {
		padding: 25px;
	}
	
}
.ed-room i.room-zoom{
	position: absolute;
	top: 50%;
	right: 50%;
	z-index: 999;
	margin-top: -75px;
	margin-right: -20px;
	width: 35px;
	height: 35px;
	background: rgba(0, 0, 0, 0.7);
	color: rgba(255, 255, 255, 0.7);
	font-size: 15px;
	border-radius:50%;
	text-align: center;
	padding-top: 9px;
	@include tablet {
		background: rgba(255, 255, 255, 0.4);
		color: rgba(0, 0, 0, 0.7);
		margin-top: 0;
		margin-right: 0;
		top: 5px;
		right: 5px;
		width: 30px;
		height: 30px;
		font-size: 14px;
		padding-top: 8px;
	}
	@include desktops {
		top: 10px;
		right: 10px;
	}
	@include large-desktops {
		padding-top: 9px;
		width: 35px;
		height: 35px;
		top: 15px;
		right: 15px;
		font-size: 15px;
	}
	
}
.ed-room.featured-room	figcaption .headline{
	margin-bottom: 30px;
	color:$light;
	text-decoration: none;
	font-weight: 700;
	letter-spacing:1px;
	font-family: $secondary-font;

	@include tablet {
		margin-bottom: 7px;
	}
	@include desktops {
		margin-bottom: 10px;
	}
	
	a{
		color: $light;
		text-decoration: none;
	}
}
.ed-room.featured-room	figcaption p{
	@include tablet {
		font-size: 12px;
		line-height: 16px;
		display: block;
	}
	@include desktops {
		font-size: 14px;
		line-height: 18px;
	}
	@include large-desktops {
		font-size: 15px;
		line-height: 24px;
	}
}
.room-price{
	font-size: 20px;
    font-weight: 500;
    position: absolute;
    bottom: 20px;
    display: inline-block;
    @include mobile {
		font-size: 30px;
	}
    @include tablet {
		font-size: 20px;
	}
	@include large-desktops {
		font-size: 30px;
	}
	
    span{
    	color: $light;
    	font-size: 12px;
    	font-weight: 300;
    	display: inline-block;
    	margin: 0 5px;
		@include large-desktops {
			font-size: 15px;
		}
    }
}
.white-popup-block .room-price span{
	color: #979797;
}
.ed-room.featured-room .room-price{
	color: $light;
	bottom: 20px;
	@include tablet {
		bottom: 10px;
	}
	@include large-desktops {
		bottom: 25px;
	}
	
}
.ed-member{
	color: $light;
	position: absolute;
	right: 20px;
	bottom: 20px;
	font-size: 20px;
	font-weight: 500;
	@include mobile {
		font-size: 30px;
	}
	@include tablet {
		right: 10px;
		bottom: 10px;
		font-size: 20px;
	}
	@include large-desktops {
		right: 25px;
		bottom: 25px;
		font-size: 30px;
	}
	span{
		font-size: 12px;
		font-weight: 300;
		margin-left: 5px;
		@include large-desktops {
			font-size: 15px;
		}
	}
}
.corner-ribbon {
	position: absolute;
	left: -5px; top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 35%; 
	height: 58%;
	text-align: right;
}
.ed-room.featured-room .corner-ribbon{
	width: 36%;
	@include mobile {
		width: 30%;
	}
	@include tablet {
		width: 57%;
	}
	@include desktops {
		width: 41%;
	}
	@include large-desktops {
		width: 35%; 
	}

}
.corner-ribbon span {
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	line-height: 30px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 99%;
	display: block;
	background: $primary-color;
	color: $light;
	position: absolute;
	top: 19px; 
	left: -27px;
	@include tablet {
		font-size: 12px;
	}
	@include desktops {
		font-size: 14px;
	}
	
}
.ed-room-highlight .corner-ribbon span{
	background: $secondary-color;
}
.corner-ribbon span::before {
	content: "";
	position: absolute; 
	left: 0px; 
	top: 100%;
	z-index: -1;
	border-left: 3px solid $primary-ribbon-shade;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid $primary-ribbon-shade;
}
.corner-ribbon span::after {
	content: "";
	position: absolute; 
	right: 0px; 
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid $primary-ribbon-shade;
	border-bottom: 3px solid transparent;
	border-top: 3px solid $primary-ribbon-shade;
}
.ed-room-highlight .corner-ribbon span::before{
	border-left: 3px solid $secondary-ribbon-shade;
	border-top: 3px solid $secondary-ribbon-shade;
} 
.ed-room-highlight .corner-ribbon span::after{
	border-right: 3px solid $secondary-ribbon-shade;
	border-top: 3px solid $secondary-ribbon-shade;
}


// Hover Image Text
.ed-room.featured-room figcaption{
	display: block;
	background-color: rgba(0, 0, 0, 0.0);
    -webkit-transition: background-color 0.5s; 
     transition: background-color 0.5s; 
}
.ed-room.featured-room:hover figcaption{
	background-color: rgba(0, 0, 0, 0.7);
}
.ed-room.featured-room	figcaption .headline{
	opacity: 0;
	-webkit-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    transform: translate(0, 100px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
}
.ed-room.featured-room:hover figcaption .headline{
	opacity: 1;
	-webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);	
}
.ed-room i.fa-search{
	opacity: 0;
	-webkit-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    transform: translate(0, 100px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
}
.ed-room.featured-room:hover i.fa-search{
	opacity: 1;
	-webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);	
}
.ed-room.featured-room	figcaption p{
	opacity: 0;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    color: $light;
}
.ed-room.featured-room .feature-icon{
	opacity: 0;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.ed-room.featured-room:hover .feature-icon{
	-webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    opacity: 1;
}
.ed-room.featured-room:hover figcaption p{
	-webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}
.ed-room.featured-room	figcaption .room-price, 
.ed-room.featured-room	figcaption .ed-member{
	opacity: 0;
    -webkit-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.45s, transform 0.45s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
.ed-room.featured-room:hover figcaption .room-price, .ed-room.featured-room:hover figcaption .ed-member{
	-webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}
.ed-room.featured-room .corner-ribbon{
	opacity: 1;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
.ed-room.featured-room:hover .corner-ribbon{
	-webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    opacity: 0;
}


.ed-room.featured-room figcaption {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    display: block;
    background-color: transparent;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
    padding: 25px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;

}


.feature-icon {
	span{
		color: $light;
		font-size: 14px;
		margin-right: 5px;
	}
}



@include mobile {
  .feature-icon{
  	display: none;
  }

 .ed-room.featured-room figcaption p {
    font-size: 12px;
   }

  .ed-booking-tab .nav-tabs li:first-child span.ed-step-bar{
   	display: none;
   }

   .ed-booking-tab .nav-tabs li:last-child span.ed-step-bar {
    	display: none;
	}
}