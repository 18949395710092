/*================= 12. BOOKING STEP ==================*/
.booking-page .filter-container .col-xs-12 {
    margin-bottom: 8px;
}
.ed-booking-tab .nav-tabs{
	padding: 0 35px;
	margin-bottom: 40px;
	border: none;
	li{
		width: 33.3333%;
		a{
			display: block;
			color: $black;
			font-size: 12px;
			font-weight: 400;
			text-transform: uppercase;
			text-decoration: none;
			margin: 0;
			padding: 0;
			border: none;
			position: relative;

			@include mobile {
				font-size: 15px;
			}
			&:hover, &:focus{
				background: transparent;
			}
			span.ed-step{
				border: 2px solid transparent;
				background: transparent;
				width: 20px;
				height: 20px;
				display: inline-block;
				 border-radius:50%;
				@include mobile {
					width: 25px;
					height: 25px;
				}
			}
			span.ed-step-fill{
				background: #e6eaf0;
				width: 17px;
				height: 17px;
				display: inline-block;
				 border-radius:50%;
				@include mobile {
					width: 22px;
					height: 22px;
				}
			}
			span.ed-step-bar{
				display: block;
			    width: 125%;
			    height: 2px;
			    background-color: #e6eaf0;
			    position: absolute;
			    top: 13%;

			     @include mobile {
					width: 97%;
					top: 19%;
				}
			    @include tablet {
					width: 122%;
				}
				@include desktops {
					width: 128%;
				}
				@include large-desktops {
					width: 132%;
				}  
				 
			}
			span.ed-step-text{
				display: block;
				margin-top: 20px;
			}
		}
	}
	li:first-child{
		
		span.ed-step-bar{
			left: 25px;
			left: 35px;
			@include mobile {
				left: 40px;
			}
		}
	}
	li:last-child {
		text-align: right;
		
		span.ed-step-bar{
			right: 25px;
			@include mobile {
				right: 40px;
			}
		}
	}
	li:nth-child(2){
		text-align: center;
		a span.ed-step-text{
			margin-left: 0;
		}
	}
	li.active{
		a, a:hover, a:focus{
			border: none;
			span.ed-step-fill{
				background: $primary-color;
			}
		} 
	}
	li.ed-done{
		a{
			span.ed-step{
				border: 2px solid $primary-color;
			}
			span.ed-step-fill{
				background: transparent;
			}
		}
	}
}

/*=== 12.1 BOOKING STEP1 ===*/
.ed-room-select{
	width: 30px;
	height: 30px;
	background: transparent;
	border: 2px solid $light;
	display: inline-block;
	border-radius:50%;
	position: absolute !important;
	top: 15px;
	right: 10px;
	z-index: 9999;
	text-align: center;
	span.ed-room-select-fill{
		width: 15px;
		height: 14px;
		background: $primary-color;
		display: inline-block;
		border-radius:50%;
		vertical-align: middle;
		margin-top: -3px;
	}
}
.booking-page .headline{
	color: $black;
	margin-bottom: 30px;
}

/*=== 12.2 BOOKING STEP2 ===*/
.ed-reservation{
	background: #202122;
	padding: 25px;

	@include mobile {
		padding: 30px;
	}
	h4{
		font-size: 16px;
		margin-bottom: 15px;
		font-weight: 400;
		color: $light;
	}

	.headline{
		font-weight: 700;
		color: $light;
	}
}
ul.ed-reservation-detail {
	background: $light;
	border-radius: 5px;
	margin-bottom: 35px;
	margin-top: 25px;
	li{
		display: table;
		border-bottom: 1px solid $border-color;
		width: 100%;
		span{
			display: table-cell;
			font-weight: 400;
			vertical-align: top;
		}
		span:first-child{
			width: 40%;
			padding:10px;
			border-right: 1px solid $border-color;
		}
		span:last-child{
			padding: 10px 20px;
		}
		img{
			border-radius:5px;
			margin-bottom: 5px;
		}
	}
	li:last-child{
		border-bottom: none;
	}
	li:first-child{
		span:last-child{
			color: $primary-color;
		}
	}
}
.ed-total{
	background: $light;
	border-radius:5px;
	text-align: center;
	width: 100%;
	float: left;
	margin-bottom: 35px;
}
.ed-total > span{
	font-weight: 700;
	width: 30%;
	border-right: 1px solid $border-color;
	display: inline-block;
	float: left;
	padding: 30px 0;
	span{
		font-size: 30px;
		display: block;
	}
}
.ed-total-price{
	width: 40%;
	display: inline-block;
	span{
		font-size: 25px;
		display: block;
	}
	span.offer-price{
		font-weight: 400;
		text-decoration: line-through;
		border-bottom: 1px solid $border-color;
		padding: 15px 0;
	}
	span.total-price{
		font-weight: 500;
		color: $primary-color;
		padding: 15px 0;
	}
}
.ed-pay-card{
	margin: 0 -5px;
	text-align: center;
	ul li{
		@include desktops {
			margin-bottom: 5px;
		}
		@include tablet{
			margin-bottom: 5px;
		}
		img{
			border-radius:5px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			width: 100%;
		}
	}
}
.tab-content .comment-form {
	background: $secondary-color;
	border: 1px solid $border-color;
    @include tablet {
		margin-top: 0;
	}
}
.tab-content .comment-form .confirm-btn{
	@include desktops {
		margin-right: 30px;
	}
}

span.policy{
	color: $black;
	font-weight: 400;
	a{
		color: $primary-color;
		text-decoration: none;
	}
}
input[type=checkbox], input[type=radio] {
    margin: 0 10px 0 0;
    line-height: normal;
}
@include tablet {
	#client-details > .row, #ed-payment > .row{
		display: flex;
	}
	#client-details > .row > .col-md-4, 
	#ed-payment > .row > .col-md-4{
		display: flex;
	}
	#client-details > .row > .col-md-8, 
	#ed-payment > .row > .col-md-8{
		padding-left: 0;
		display: flex;
	}
	#client-details > .row > .col-md-8 .comment-form {
		padding: 30px;
	}
	#client-details > .row > .col-md-8 .comment-form, 
	#ed-payment > .row > .col-md-8 .comment-form{
		border: 1px solid $border-color;
		border-left: none;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}
}

/*=== 12.3 BOOKING STEP3 ===*/
#ed-payment .comment-form{
	width: 100%;
	padding: 30px;
}


/*================= 13. BOOKING CONFIRMATION ==================*/
.ed-alert.alert-success {
	font-size: 20px;
	font-weight: 300;
    color: #5ca05e;
    background-color: #edfaee;
    border-color: #d0edce;
    padding: 15px 35px;
    margin-bottom: 30px;
    .close{
    		filter: alpha(opacity=100);
		    opacity: 1;
	    &:focus, &:hover {
		    color: #5ca05e;
		    filter: alpha(opacity=100);
		    opacity: 1;
		}
	}
	.icomoon-checkmark3{
		font-size: 30px;
		width: 45px;
		height: 45px;
		text-align: center;
		background: #60a662;
		color: $light;
		display: inline-block;
		border-radius:50%;
		vertical-align: middle;
		margin-right: 10px;
	}
	.icomoon-checkmark3:before {
		vertical-align: middle;
	}
}
.ed-alert.alert-dismissable .close, .ed-alert.alert-dismissible .close {
    position: relative;
    top: -14px;
    right: -28px;
}
.ed-cinfirm{
	padding: 15px;
	border: 1px solid $border-color;
	width: 100%;
	float: left;
}
.ed-cinfirm .top-headline{
	margin-top: 30px;
}
.ed-cinfirm-detail{
	.headline{
		color: $black;
		margin-bottom: 30px;
	}
	li{
		margin: 0 10px 10px 0px;

		@include desktops {
			width: 100%;
			margin: 0;
			margin-bottom: 30px;
		}
		span{
			font-weight: 700;
			color: $black;
			width: 90px;
			display: inline-block;
		}
		
	}
	
}


.payment-confirm{
	background: $secondary-color;
	padding: 60px 30px;
}


.comment-form  .ed-pay-card {
    width: 350px;
}


.confirm-btn{
	margin-top: -10px;
}


.datepicker table tr td.active.active{
	background: $primary-color;
}