// Fonts 
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700|Playfair+Display:400,700');



body {
  line-height: 27px;
  font-family: $secondary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;

}
h1,h2,h3,h4,h5,h6 {
  font-family: $primary-font; 
}
h1, h2, h3, h4, h5, h6 {
	font-weight:700;
}
h1 {
  font-size:40px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size:18px;
}
p {
  color:$black-lighter;
  font-size:15px;
  font-family: $secondary-font;
}
