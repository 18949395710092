// Service Pages

.service-box{
	transition:all .3s ease 0s;

	i{
		font-size: 60px;
		line-height: 60px;
	}

	h3{
		font-family: $secondary-font;
	}

	&:hover{
		background: #f0f0f0;
	}
}

.service-cta-text{
	ul {
		li{
			padding: 6px 0px;
			span{
				color: $primary-color;
				margin-right: 10px;
				font-size: 22px;
			}
		}
	}
}