/*=================================================================
  Latest Posts
==================================================================*/

.post {
  background: $light;

  .post-media {
    &.post-thumb {
      img {
        width: 100%;
        height:auto;
        max-height:350px;
      }
    }
    &.post-media-audio {
      iframe {
        width: 100%;
      }
    }
  }

  .post-title {
    a {
      color: $black-light;
      &:hover {
        color: $primary-color;
      }
    }
  }

  .post-meta {
    font-size: 13px;
    ul {

      li {
        display: inline-block;
        color:$black-light;
        margin-right: 20px;
        font-size: 12px;
        letter-spacing: .5px;

        i{
            color:$primary-color;
        }

        a {
         color:$black-light;
          &:hover {
            color:$primary-color;
          }
          
        }
      }

      li:first-child{
        font-weight: 700;

      }
    }
    .post-author {
      color:$black;
    }
  }

  .post-content {

      p {
        line-height:26px;
      }

    
    .btn-main {
      padding:10px 20px;
      margin:15px 0;
      font-size:12px;
    }
  }
}

.post-pagination {
  margin-top: 70px;
  >li {
    margin:0 2px;
    display: inline-block;
    font-size:14px;
    >a {
      color: $black;
      padding: 5px;
      border:1px solid transparent;
      @include transition (all, 0.3s, ease);
      
      &:hover {
        border:1px solid $primary-color;
      }
    }
    &.active>a {
      border:1px solid $primary-color!important;
    }
  }
  >li:first-child>a, >li:last-child>a {
    border-radius: 0;
  }
}


.post{
  .post-thumb{
    img{
      width: 100%;
    }
  }
}


// Home Blog Section


.blog{
  .card-body{
    a h3{
      color: $black;
      line-height: 28px;

      &:hover{
        color: $primary-color;
      }
    }

  }


}

