$light: #fff;
$primary-color: #b0914f;
$secondary-color: rgba(240, 240, 240,.4);
$success: #1bbb1b;
$black: #000;
$black-light: #333;
$black-lighter: #7b7b7b;
$alert:#c7254e;
$border-color:#dedede;
$primary-font:'Playfair Display', sans-serif;
$secondary-font:'Nunito Sans', sans-serif;
$primary-ribbon-shade:#23658f;
$secondary-ribbon-shade:#a33a3a;