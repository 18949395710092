.gallery-feed{
	position: relative;

	.gallery-title{
		position: absolute;
		text-align: center;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		z-index: 1;
	}

}

.gallery-item{
	a{
		display:block;
	}
}

       
       
       