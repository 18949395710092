
iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
  color: $primary-color;
}

blockquote {
  font-size: 18px;
  border-color: $primary-color;
  padding: 20px 40px;
  text-align: left;
  color:#777;
}
.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type="email"],input[type="password"],input[type="text"],input[type="tel"]{
  box-shadow:none;
  height:45px;
  outline: none;
  font-size:14px;
  &:focus {
    box-shadow: none;
    border:1px solid $primary-color;
  }
}


.form-control {
  box-shadow: none;
  border-radius: 0;
  &:focus {
    box-shadow:none;
    border:1px solid $primary-color;
  }
}



// Backgrounds


.secondary-bg{
  background: #f0f0f0;
}

 
.gray-bg{
  background: #313a45;
}

 .text-color{
  color: $primary-color;
 }

 .text-dark{
  color: $black!important;
 }
 

 .border{
    border-color: #f0f0f0!important;
 }

 .card{
  border-color: #f0f0f0!important;
 }
// Button Style


.btn{
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 13px 20px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius:0;
}
.btn-main {
  background: $primary-color;
  color: $light;
  @include transition (all, 0.2s, ease);

  &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }

  &:hover {
    background: darken( $primary-color, 20% );
    color: $light;
    border-color:#6a572f;
  }
}


.btn-solid-border {
  border:1px solid $black-lighter;
  background:transparent;
  color:$black;

  &:hover {
    border:1px solid $primary-color;
    background:$primary-color;
    color: $light;
  }
}


.btn-transparent {
  @extend .btn-main;
  background:transparent;
  padding:0;
  color:$primary-color;

  &:hover {
    background:transparent;
    color:$primary-color;
  }
}

.btn-large {
  padding:20px 45px;

  &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }
}
.btn-small {
  @extend .btn-main ;
  padding:10px 25px;
  font-size:12px;
}

.btn-round {
  border-radius:4px;
}
.btn-round-full {
  border-radius:50px;
}


.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}




#preloader {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}




.section {
  padding:100px 0;
}



.section-title {
  margin-bottom: 70px;
  h2 {
    font-size: 48px;
  }
 
}

.section-subtitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 2.14;
  letter-spacing: 2.8px;
  text-align: center;
  color: $primary-color!important;
  text-transform: uppercase;
}

.section-border {
  width: 40px;
  height: 3px;
  background-color: $primary-color;
  margin: 0 auto;
  display: block;
}




.page-border-top{
  border-top: 1px solid rgba(255,255,255,.05);
}
.page-breadcumb{

  a{
    opacity: .7;
  }

  i{
    @extend a;
  }
}


.heading{
  padding-bottom: 60px;
  text-align: center;
  h2{
    color: #000;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
  }
  p{
    font-size: 18px;
    line-height: 40px;
    color: #292929;
    font-weight: 300;
  }
}



.page-wrapper {
  padding:70px 0;
}



.overly {
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background:rgba(0, 0, 0,.5);
  }
}

.py-100{
  padding: 120px 0px 90px;
}

.btn:focus {
    outline: 0;
    box-shadow: none;
}